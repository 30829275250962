import React from "react"

export default function Sidebar({ classes }) {
  const otherClasses = classes ? classes : ""
  return (
    <aside className={`mt-24 ${otherClasses}`}>
      <div className="rounded-lg leading-6 blue-gradient text-white p-8">
        <h3 className="text-lg">ADRESSE</h3>
        <p>25 rue du champ de Foire</p>
        <p>La Croisille-sur-Briance – 87130</p>
        <h3 className="text-lg">TÉLÉPHONE</h3>
        <p>Cabinet : 09 72 62 76 30 </p>
        <p>SAMU : 15</p>
        <h3 className="text-lg">HORAIRES</h3>
        <p>
          Visites à domiciles et consultations sur rendez-vous par téléphone au
          <br />
          09 72 62 76 30
        </p>
        <br />
      </div>
    </aside>
  )
}
