import React from "react"
import Seo from "../components/site-metadata"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import Sidebar from "../components/sidebar"
import Title from "../components/title"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const LeCabinet = ({ intl }) => {
  const title = intl.formatMessage({ id: "le-cabinet.title" })
  const description = intl.formatMessage({ id: "le-cabinet.description" })
  return (
    <>
      <main className="h-[calc(100vh-50px)]">
        <Seo
          title={`${title} de la Docteure Debord`}
          description={description}
        />
        <Header />
        <Container classes={"md:flex"}>
          <section className="md:w-3/4">
            <Title title={title} />
            <FormattedHTMLMessage id="le-cabinet.content" />
          </section>
          <Sidebar classes={"md:w-1/4"} />
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default injectIntl(LeCabinet)
